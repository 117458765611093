const PrivacyPolicy = ({ mobile }: { mobile: boolean }) => {
  const comp = "Davoody & Friends AB";
  const contact = "a@alidavoodi.com";
  const org = "556773-3547";
  const address = "Stuartsgatan 2, 412 60 Gothenburg, Sweden";
  return (
    <div className="flex flex-col gap-[1rem] ">
      <h1>PERSONUPPGIFTSPOLICY // PRIVACY POLICY</h1>
      <div>Din personliga integritet är viktig för oss på {comp}.</div>
      <div>
        <div>
          I denna policy beskrivs vår insamling och behandling av personliga
          information om dig, samt de rättigheter du har gentemot oss och hur du
          gör dessa gällande.
        </div>
        <div>
          Du är välkommen att kontakta oss på {contact} vid eventuella frågor.
        </div>
      </div>
      <div>Your privacy is important to us at {comp}.</div>
      <div>
        <div>
          This policy describes our collection and processing of personal
          information about you, as well as the rights you have towards us and
          how you can assert them.
        </div>
        <div>
          You are welcome to contact us at {contact} if you have any questions.
        </div>
      </div>
      <div>
        1. VAD ÄR EN PERSONUPPGIFT OCH VAD INNEBÄR BEHANDLING AV
        PERSONUPPGIFTER?
      </div>
      <div>
        Personuppgifter är all slags information som direkt eller indirekt kan
        kopplas till en fysisk person i livet. Det kan vara till exempel namn,
        adress, telefonnummer och mejladress eller även bilder och annan media
        som behandlas i en dator, även om inga namn specifikt anges. Även
        exempelvis krypterade information och olika slags elektroniska
        identiteter som tex IP-nummer är också personuppgifter om de kan knytas
        till dig som fysisk person. Behandling av personuppgifter är de åtgärder
        som sker med personuppgifterna, oavsett om den sker per automatik eller
        ej. Personuppgiftsbehandlingar kan exempelvis vara insamling,
        samkörning, strukturering, lagring, bearbetning, överföring och
        radering.
      </div>
      <div>
        1. WHAT IS PERSONAL DATA AND WHAT DOES PERSONAL DATA PROCESSING INCLUDE?
      </div>
      <div>
        Personal data is any kind of information that can be directly or
        indirectly linked to a living physical person. It can be, for example,
        name, address, phone number and email address or even images and other
        media that are processed on a computer, even if no names are
        specifically stated. Also, for example, encrypted information and
        various types of electronic identities such as IP numbers are also
        personal data if they can be linked to you as a natural person.
        Processing of personal data is the activites that take place with the
        personal data, regardless of whether it takes place automatically or
        not. Personal data processing can be, for example, collection,
        aggregation, structuring, storage, processing, transfer and deletion.
      </div>
      <div>2. INSAMLING AV PERSONUPPGIFTER</div>
      <div>
        A. Vi samlar bland annat in personuppgifter från dig när vi analyserar
        ditt beteende vid besök på vår hemsida (exempelvis IP-nummer, besökta
        sidor, klick och sökningar).
      </div>
      <div>
        B. Vi samlar även in uppgifter från dig när du lämnar information till
        oss genom att fullfölja köp (exempelvis namn, mejladress, telefonnummer,
        betalningsinformation och varor. Även, orderbekräftelse,
        leveransinformation, och returer).
      </div>
      <div>
        C. Vi samlar även in personuppgifter från tredje parter, exempelvis i
        samband med ev. kreditprövningar.
      </div>
      <div>2. COLLECTING OF PERSONAL DATA</div>
      <div>
        A. Among other things, we collect personal data from you when we analyze
        your behavior when visiting our website (e.g. IP number, pages visited,
        clicks and searches).
      </div>
      <div>
        B. We also collect data from you when you provide information to us by
        completing a purchase (for example, name, email address, phone number,
        payment information and products. Also, order confirmation, delivery
        information, and returns).
      </div>
      <div>
        C. We also collect personal data from third parties, for example in
        connection with any credit checks
      </div>
      <div>3. VEM ÄR PERSONUPPGIFTSANSVARIG?</div>
      <div>
        {comp}, org-{org}, {address} är personuppgiftsansvarig för vår
        behandling av personuppgifter.
      </div>
      <div>
        Om du har några frågor om hur vi behandlar dina personuppgifter är du
        välkommen att kontakta oss på {contact}.
      </div>
      <div>3. WHO IS THE CONTROLLER?</div>
      <div>
        {comp}, org-{org}, {address} is the data controller for our processing
        of personal data.
      </div>
      <div>
        If you have any questions about how we process your personal data,
        please contact us at {contact}.
      </div>
      <div>4. VILKA PERSONUPPGIFTER BEHANDLAR VI?</div>
      <div>
        För att kunna hantera dina beställningar och köp behöver vi behandla de
        här uppgifterna om dig:
      </div>
      <div>
        (a) Namn (b) Kontaktuppgifter (c) Personnummer (för vissa
        betalningsalternativ) (d) Betalningsinformation (e)
        Beställningsinformation (exempelvis ordernummer, beställda produkter,
        leveransadress och fakturauppgifter)
      </div>
      <div>
        Denna insamling av personuppgifter krävs för att vi skall kunna fullgöra
        våra åtaganden enligt köpeavtalet. Detta gör vi med stöd av den
        rättsliga grunden fullgörande av avtal (köpeavtalet). Om uppgifterna
        inte lämnas kan våra åtaganden inte fullgöras och vi tvingas då neka dig
        köpet. Informationen sparar vi i 24 månader från det att leverans och
        betalning har skett för att kunna hjälpa dig med eventuella
        reklamations- och garantiärenden.
      </div>
      <div>4. WHICH PERSONAL DATA DO WE PROCESS?</div>
      <div>
        In order to process your orders and purchases, we need to process this
        information about you:
      </div>
      <div>
        (a) Name (f) Contact details (g) Social security number (for certain
        payment options) 3 (2) (h) Payment information (i) Order information
        (for example order number, products ordered, delivery address and
        invoice details)
      </div>
      <div>
        This collecting of personal data is required in order for us to be able
        to fulfill our obligations according to the purchase agreement. We do
        this with the support of the legal basis fulfillment of agreement
        (purchase agreement). If the information is not provided, our
        commitments cannot be fulfilled and we are then forced to deny you the
        purchase. We save the information for 24 months from the time delivery
        and payment have taken place to be able to help you with any complaints
        and warranty matters.
      </div>
      <div>
        5. VID KUNDTJÄNSTÄRENDEN KAN VI KOMMA ATT BEHANDLA FÖLJANDE UPPGIFTER OM
        DIG:
      </div>
      <div>
        (a) Namn (j) Kontaktuppgifter (k) Personnummer (för vissa
        betalningsalternativ) (l) Betalningsinformation (m)
        Beställningsinformation (exempelvis ordernummer, beställda produkter,
        leveransadress och fakturauppgifter)
      </div>
      <div>
        Detta gör vi med stöd av den rättsliga grunden att behandlingen är
        nödvändig för att tillgodose vårt och ditt berättigade intresse av att
        kunna svara på frågor från dig. Sådan behandling av dessa
        personuppgifter är nödvändig för att vi skall kunna tillgodose ditt och
        vårt intresse av att hantera kundserviceärenden. Vi sparar informationen
        till dess att ärendet har avslutats.
      </div>
      <div>
        5. WHEN PROVIDING CUSTOMER SERVICE, WE MAY PROCESS THE FOLLOWING DATA
        ABOUT YOU
      </div>
      <div>
        (a) Name (n) Contact details (o) Social security number (for certain
        payment options) (p) Payment information Order information (for example
        order number, products ordered, delivery address and invoice details)
      </div>
      <div>
        We do this on the legal basis that the processing is necessary to
        satisfy our and your legitimate interest in being able to answer
        questions from you. Such processing of this personal data is necessary
        for us to be able to satisfy your and our interest in handling customer
        service matters. We save the information until the case has been closed.
      </div>
      <div>
        6. OM DU VILL HA VÅRT NYHETSBREV, FÅ OLIKA ERBJUDANDEN OSV BEHÖVER VI
        BEHANDLA FÖLJANDE UPPGIFTER OM DIG:
      </div>
      <div>(a) Namn (q) Mejl</div>
      <div>
        Detta gör vi med stöd av den rättsliga grunden samtycke, vilket innebär
        att vi endast samlar in dina uppgifter efter att du samtyckt till det.
        Du kan när som helst återkalla ditt samtycke. Samtycket kan enkelt
        återkallas genom att klicka på länken/bocka ur i valfritt mejlutskick
        där du kan dra tillbaka ditt samtycke.
      </div>
      <div>
        Genom insamlade uppgifter från dig som kund, kan vi även skicka ut
        nyhetsbrev till dig med stöd av ett berättigat intresse av att
        tillhandahålla dig som kund nyheter, reklam och erbjudanden som vi tror
        du kan vara intresserad av med anledning av vår kundrelation. I den
        behandlingen har vi tagit hänsyn till och väger ditt intresse av
        integritet i förhållande till vårt intresse och ändamål (såsom det anges
        ovan) i att utföra behandlingen.
      </div>
      <div>
        6. IF YOU WANT TO RECEIVE OUR NEWSLETTER, RECEIVE VARIOUS OFFERS, ETC.,
        WE NEED TO PROCESS THE FOLLOWING DATA ABOUT YOU
      </div>
      <div>(a) Name (r) Email</div>
      <div>
        We do this on the legal basis of consent, which means that we only
        collect your data after you have consented to it. You can revoke your
        consent at any time. Consent can be easily revoked by clicking on the
        link/tick box in any email where you can revoke your consent.
      </div>
      <div>
        Through collected information from you as a customer, we can also send
        you newsletter based on a legitimate interest in providing you as a
        customer with news, advertising and offers that we think you may be
        interested in due to our customer relationship. In that processing, we
        have taken into account and weigh your interest in privacy against our
        interest and purpose (as set out above) in carrying out the processing.
      </div>
      <div>7. RÄTTIGHETER</div>
      <div>
        Som kund hos {comp} har du följande rättigheter som du kan använda när
        som helst.
      </div>
      <div>
        Du kan när som helst begära information om de personuppgifter vi har om
        dig och under vissa omständigheter har du rätt att få dina
        personuppgifter raderade, till exempel om behandlingen stödjer sig på
        ett samtycke och du återkallat detta samtycke. Detta gäller dock inte om
        vi är skyldiga enligt lag att behålla uppgifterna. Du har även rätt till
        att när som helst begära att bli tillhandahållen de uppgifter du har
        gett oss i ett allmänt använt maskinläsbart format för överföring till
        en annan specifik mottagare. Du har rätt till att invända mot
        behandlingen av dina uppgifter baserat på vissa skäl. Exempelvis kan du
        göra invändningar mot behandlingen av dina personuppgifter om syftet är
        direktmarknadsföring samt profilering. Vi vill säkerställa att dina
        personuppgifter är korrekta och uppdaterade, och du har även rätt att få
        felaktiga uppgifter om dig rättade.
      </div>
      <div>
        Om de uppgifter du lämnat till oss förändras, t.ex. om du ändrar din
        mejladress, namn eller dina betalningsuppgifter, eller om du vill säga
        upp ditt konto, är det bara att höra av dig till oss på
        {contact}. I samband med några av dina andra rättigheter har du rätt att
        begära begränsad databehandling, istället för att slutföra raderingen,
        eller under bedömningen av din invändning. Detta kan dock medföra att vi
        inte kan uppfylla våra eventuella skyldigheter gentemot dig. Dessa
        rättigheter är förenliga med GDPR.
      </div>
      <div>7. RIGHTS</div>
      <div>
        As a customer of {comp}, you have the following rights that you can
        exercise at any time.
      </div>
      <div>
        You can at any time request information about the personal data we have
        about you and in certain circumstances you have the right to have your
        personal data 5 (2) deleted, for example if the processing is based on
        consent and you revoke this consent. However, this does not apply if we
        are obliged by law to retain the data. You also have the right to
        request at any time that we provide you with the information you have
        provided to us in a commonly used machine-readable format for
        transmission to another specific recipient. You have the right to object
        to the processing of your data based on certain reasons. For example,
        you can object to the processing of your personal data if the purpose is
        direct marketing and profiling. We want to ensure that your personal
        data is correct and up-to-date, and you also have the right to have
        incorrect information about you corrected.
      </div>
      <div>
        If the information you provided to us changes, e.g. if you change your
        email address, name or your payment details, or if you want to cancel
        your account, just contact us at {contact}. In connection with some of
        your other rights, you have the right to request limited data
        processing, instead of a complete deletion, or during the assessment of
        your objection. However, this may mean that we cannot fulfill our
        possible obligations towards you. These rights are compatible with the
        General Data Protection Regulation.
      </div>
      <div>8. SAMTYCKE</div>
      <div>
        För sådan personuppgiftsbehandling som vi har begärt ditt samtycke till
        har du rätt att, när som helst, återkalla ditt samtycke till
        behandlingen med framtida verkan. Det betyder att om du återkallar ditt
        samtycke har vi inte rätt att fortsätta behandla redan insamlade
        personuppgifter med stöd av ditt tidigare samtycke. Genom att klicka på
        aktuell länk i mejl där samtycke används som rättslig grund, kan du
        enkelt dra tillbaka ditt samtycke.
      </div>
      <div>8. CONSENT</div>
      <div>
        For such personal data processing for which we have requested your
        consent, you have the right, at any time, to withdraw your consent to
        the processing with future effect. This means that if you withdraw your
        consent, we do not have the right to continue processing already
        collected personal data based on your previous consent. By clicking on
        the relevant link in an email where consent is used as a legal basis,
        you can easily withdraw your consent.
      </div>
      <div>9. COOKIES - VAD ÄR EN COOKIE?</div>
      <div>
        En cookie är en textfil som sparar information om ditt besök på hemsidan
        och möjliggör att identifiera dig som besökare på en webbplats. Vid
        besökpå samma webbplats igen så kan data som är sparad i cookien hämtas
        upp och uppdatera webbplatsen om dina tidigare aktiviteter. Med hjälp av
        cookies kan webbplatsen till exempel hålla reda på vilka varor du har
        placerat i varukorgen. I många fall är cookies nödvändiga för att man
        ska kunna tillhandahålla en viss tjänst på webbplatsen.
      </div>
      <div>9. COOKIES - WHAT IS A COOKIE?</div>
      <div>
        A cookie is a text file that registers your visit to the website and
        makes it possible to identify you as a visitor to a website. When you
        visit the same website again, the data saved in the cookie can be
        retrieved and update the website about your previous activities. With
        the help of cookies, the website can, for example, keep track of which
        products you have placed in the shopping cart. In many cases, cookies
        are necessary to be able to provide a certain service on the website.
      </div>
      <div>10. VILKA TYPER AV COOKIES ANVÄNDER VI OCH VARFÖR?</div>
      <div>
        Vi använder oss av både mer varaktiga cookies och kortvariga cookies för
        att förbättra kundupplevelsen för dig som kund. Dessa cookies används
        även för att erbjuda dig en mer personlig upplevelse på andra
        webbplatser så som Facebook och Instagram.
      </div>
      <div>
        Den ena typen av cookies kallas för Session Cookies och är mer
        kortvarig. De lagras temporärt i ditt kommunikationsmedel under den tid
        som du besöker en webbplats. Denna typ av cookies används exempelvis för
        att hålla reda på vilket språk som du har valt för den aktuella
        webbplatsen och försvinner när du stänger ned din webbläsare. Den andra
        typen av cookie är mer varaktig och sparar en textfil under en längre
        tid på din dator. När utgångsdatumet för den aktuella cookien passerats
        raderas den från din dator.
      </div>
      <div>10. WHAT TYPES OF COOKIES DO WE USE AND WHY?</div>
      <div>
        We use both more persistent cookies and short-term cookies to improve
        the customer experience for you as a customer. These cookies are also
        used to offer you a more personal experience on other websites such as
        Facebook and Instagram.
      </div>
      <div>
        One type of cookie is called Session Cookies and they are more
        short-lived. They are temporarily stored in your means of communication
        during the time you visit a website. This type of cookie is used, for
        example, to keep track of which language you have chosen for the current
        website and disappears when you close your browser. The second type of
        cookie is more persistent and saves a text file for a 7 (2) longer
        period of time on your computer. When the expiration date of the current
        cookie has passed, it is deleted from your computer.
      </div>
      <div>10.1 NÖDVÄNDIGA COOKIES</div>
      <div>
        Vi använder nödvändiga cookies för att hemsidan ska fungera korrekt. De
        innehåller information som vi måste ha för att du ska kunna få tillgång
        till vissa tjänster. Till exempel för att vi ska komma ihåg vilka
        produkter du har lagt i din kundvagn eller hur långt du har kommit i din
        beställning. Behandling av cookies i denna kategori baseras på vårt
        berättigade intresse att förstå och förbättra hur våran sajt används.
      </div>
      <div>10.1 NECCESSARY COOKIES </div>
      <div>
        We use necessary cookies for the website to function correctly. They
        contain information that we must have in order for you to access certain
        services. For example, for us to remember which products you have put in
        your shopping cart or how far you have come in your order. Treatment of
        cookies in this category is based on our legitimate interest in
        understanding and improving how our site is used.
      </div>
      <div>10.2 COOKIES FÖR MARKNADSFÖRING</div>
      <div>
        Vi använder cookies för att visa annonser och erbjudanden som vi tror du
        är intresserad av och som är anpassade efter dig. De används för att
        samla information för annonsering och skräddarsytt innehåll samt för
        webbstatistik (det är tredjepartscookies som gör att du t.ex. ser
        annonser på produkter som du tidigare har klickat eller gjort sökningar
        på). Dessa cookies hanterar vi baserat på vårt berättigade intresse av
        att kunna ge så relevant information till dig som möjligt, men du har
        alltid möjlighet att inaktivera dem och därmed upphör vår hantering av
        din användarinformation för att visa anpassade annonser.
      </div>
      <div>10.2 MARKETING COOKIES</div>
      <div>
        We use cookies to display advertisements and offers that we think you
        are interested in and that are tailored to you. They are used to collect
        information for advertising and tailored content as well as for web
        statistics (these are third-party cookies that allow you to, for
        example, see ads on products that you have previously clicked on or
        searched for). We manage these cookies based on our legitimate interest
        in being able to provide you with as relevant information as possible,
        but you always have the option to disable them and thus our processing
        of your user information to display customized ads ceases.
      </div>
      <div>10.3 COOKIES FÖR ANALYS </div>
      <div>
        Vi samlar in viss information genom cookies i analytiskt syfte för att
        förbättra vår sajt och ge oss information om vår marknadsföring. För att
        skapa en bättre upplevelse för dig använder vi olika analysverktyg som
        Google Analytics, som hjälper oss att samla in statistik om hur vår
        webbplats används. För att avgöra vilka som besöker vår webbplats
        använder vi cookies för att skapa demografisk och användarrelaterad
        statistik.
      </div>
      <div>10.3 ANALYSIS COOKIES</div>
      <div>
        We collect certain information through cookies for analytical purposes
        to improve our site and provide us with information about our marketing.
        To create a better experience for you, we use various analysis tools
        such as Google Analytics, 8 (2) which help us collect statistics about
        how our website is used. To determine who visits our website, we use
        cookies to create demographic and user-related statistics.
      </div>
      <div>11. VEM HAR TILLGÅNG TILL INFORMATIONEN FRÅN COOKIES? </div>
      <div>
        Informationen som inhämtas genom cookies har vi på {comp} samt våra
        tredjeparter tillgång till för att kunna uppfylla dess syfte. Dina
        uppgifter delas även med externa distributörer för leverans av dina
        försändelser samt information om leverans.
      </div>
      <div>11. WHO HAS ACCESS TO THE DATA FROM COOKIES? </div>
      <div>
        We at {comp} and our third parties have access to the information
        obtained through cookies in order to fulfill its purpose. Your data is
        also shared with external distributors for delivery of your shipments
        and information about delivery.
      </div>
      <div>12. DITT SAMTYCKE TILL VÅRT ANVÄNDANDE AV COOKIES</div>
      <div>
        Om du önskar kan du avstå från att lämna samtycke och har även möjlighet
        att återkalla ditt samtycke vilket du gör genom att ändra
        inställningarna i din dator så att denna a inte längre tillåter
        användande av cookies. Om du inte vill ta emot cookies kan du välja att
        blockera alla cookies, radera befintliga cookies från din dator eller
        ställa in datorn så att du får en varning innan cookies sparas. Vår
        rekommendation är att du läser igenom vår cookiepolicy noga innan du
        ändrar dina cookieinställningar i din dator. Om du ändrar
        cookieinställningarna kan din användarupplevelse försämras när du
        besöker webbplatser. Tänk på att om du inte accepterar cookies kan det
        finnas funktioner på webbplatsen som inte kommer att fungera för dig.
      </div>
      <div>12. YOUR CONSENT TO OUR USE OF COOKIES</div>
      <div>
        You have the option to refrain from giving consent and also to revoke
        your consent, which you do by changing the settings on your computer so
        that it no longer allows the use of cookies. If you do not want to
        receive cookies, you can either block all cookies, delete existing
        cookies from your computer or set your computer so that you receive a
        warning before cookies are saved. We recommend that you read our cookie
        policy carefully before changing your cookie settings on your computer.
        If you change the cookie settings, your user experience may deteriorate
        when you visit our and other websites. Please note that if you do not
        accept cookies, there may be features on the website that will not work
        for you.
      </div>
    </div>
  );
};

export default PrivacyPolicy;
