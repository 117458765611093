import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

export const MobileMarquee = ({
  intro,
  launch,
}: {
  intro: boolean;
  launch: boolean;
}) => {
  return (
    <>
      {!launch && (
        <motion.div
          initial={{ y: "100%" }}
          animate={{ y: intro ? "100%" : "0%" }}
          // className=" h-[5vh] w-[200vh] fixed top-[65vh] lg:top-[15vh] translate-x-[-50%]"
          className="fixed top-[50%] w-[300%] "
        >
          {" "}
          <Marquee
            direction="right"
            gradient={false}
            className="bg-white rotate-[-45deg] translate-x-[-45%]"
            // className="bg-white lg:rotate-0 rotate-[-45deg] h-[5vh] w-[200vh] lg:w-[200vw] lg:translate-x-0 translate-x-[-50%]  "
            speed={10}
          >
            | COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING
            SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON
            COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING
            SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON
            COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING
            SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON
            COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING
            SOON COMING SOON COMING SOON COMING SOON COMING SOON COMING SOON
            COMING SOON COMING SOON |
          </Marquee>
        </motion.div>
      )}

      <motion.div
        initial={{ y: "100%" }}
        animate={{ y: intro ? "100%" : "0%" }}
        className="fixed bottom-0 w-[100vw]"
      >
        <Marquee
          direction="right"
          gradient={false}
          className="bg-white"
          speed={10}
        >
          <p className="text-sm font-bold text-black flex gap-[0.3rem]">
            {" "}
            | <span className="text-black"> ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN IRAN |
            </a>{" "}
            <span className="text-black">ALL PROFIT GOES</span>{" "}
            <a
              href="/center-for-human-rights"
              target={"_blank"}
              className="underline hover:bg-[#dcb3dd] pr-[0.5rem]"
              style={{ textDecorationColor: "#dcb3dd" }}
            >
              THE CENTER FOR HUMAN RIGHTS IN{" "}
              <span className="mx-[0.1rem]">IRAN </span>
            </a>
          </p>
        </Marquee>
        <Marquee
          direction="left"
          gradient={false}
          className="bg-gray-200"
          speed={10}
        >
          <p className="text-sm font-bold text-black">
            we reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            We reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            we reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            we reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            we reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            we reserve the right for errors in writing | thank you to: ali
            davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris law |
            we reserve the right for errors in writing | thank you to: anders
            lindén, esmeralda vidfar, lukas vidfar, morris law | we reserve the
            right for errors in writing | thank you to: ali davoodi, anders
            lindén, esmeralda vidfar, lukas vidfar, morris law | thank you to:
            ali davoodi, anders lindén, esmeralda vidfar, lukas vidfar, morris
            law |
          </p>
        </Marquee>
      </motion.div>
    </>
  );
};
