import { getDocs, orderBy, query } from "firebase/firestore";
import { IMail, mailCol } from "../firebase-config";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useCart } from "../hooks/useCart";

const EmailList = ({ mobile }: { mobile: boolean }) => {
  const password = "supersecretemails123";
  const [secret, setSecret] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [hoodies, setHoodies] = useState([{ size: "", stock: 0 }]);
  const [updateButtonMessage, setUpdateButtonMessage] = useState("update");

  const [newStockM, setNewStockM] = useState(0);
  const [newStockL, setNewStockL] = useState(0);

  const testSecret = (e: React.FormEvent) => {
    e.preventDefault();
    if (secret === password) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const getMailSortedByDate = async () => {
    const itemDocs = await getDocs(query(mailCol, orderBy("date", "desc")));
    const items: IMail[] = itemDocs.docs.map((doc) => ({
      ...doc.data(),
    }));
    console.log("items", items);
    return items;
  };

  const fetchMail = async () => {
    const mails = await getMailSortedByDate();
    console.log("mails", mails);
    return mails;
  };

  const { isLoading, isSuccess, data } = useQuery("fetchMail", fetchMail, {
    refetchOnWindowFocus: false,
    staleTime: 60 * 2 * 1000,
  });

  const { checkHoodieStock, updateHoodieStock } = useCart();

  useEffect(() => {
    const handleCheck = async () => {
      const res = await checkHoodieStock();
      setHoodies(res);
      console.log("res", res);
    };
    handleCheck();
  }, []);

  return (
    <div className="p-[2rem]">
      {!confirmed && (
        <div className="flex gap-[1rem]">
          <input
            className="border-2 border-black"
            type="password"
            onChange={(e) => setSecret(e.target.value)}
            placeholder="SECRET"
          />
          <button className="border-2 border-black" onClick={testSecret}>
            Test Secret
          </button>
        </div>
      )}

      {isLoading && <div>loading...</div>}
      {isSuccess && confirmed && (
        <div className="">
          <div className="">current stock</div>
          {hoodies.map((hoodie, i) => {
            return (
              <div key={`${i}-hoodie`} className="flex gap-[1rem]">
                <div>
                  {hoodie.size}: {hoodie.stock}
                </div>
                <input
                  onChange={(e) => {
                    if (hoodie.size === "M") {
                      setNewStockM(parseInt(e.target.value));
                    } else {
                      setNewStockL(parseInt(e.target.value));
                    }
                  }}
                  type="number"
                  min={"0"}
                  placeholder={hoodie.stock.toString()}
                  className="border"
                />
                <button
                  onClick={async () => {
                    setUpdateButtonMessage("updating...");
                    await updateHoodieStock({
                      size: hoodie.size,
                      newStock: hoodie.size === "M" ? newStockM : newStockL,
                    });
                    setUpdateButtonMessage("updated!");
                  }}
                  disabled={updateButtonMessage === "updating..."}
                  className="border-2 border-black"
                >
                  {updateButtonMessage}
                </button>
              </div>
            );
          })}
          <h1 className="pt-[2rem]">Emails</h1>
          <div className="p-10 grid grid-cols-1 lg:grid-cols-2">
            {data.map((mail: IMail, i: number) => (
              <div key={`mail-${mail.id}`} className="border border-black ">
                <div>
                  <div className="flex flex-col my-[2vh] ">
                    <div># {data.length - i}</div>
                    <div className="flex flex-col my-[2vh]">
                      <div>email: {mail.email}</div>
                    </div>
                    <div>id: {mail.id}</div>
                    <div>date: {`${mail.date.toDate()}`}</div>
                  </div>

                  <div className="flex flex-col my-[2vh]"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailList;
