import { Background } from "../comps/Background";

const CenterPage = ({ mobile }: { mobile: boolean }) => {
  return (
    <div className="w-full h-full bg-[#dcb3dd]">
      <div className=" w-[100vw] h-[100vh] bg-pink-200 text-center">
        <Background mobile={mobile} />
        <div className="fixed top-0 right-0 w-[100vw] h-[100vh] flex flex-col justify-center items-center gap-[1rem] lg:gap-[2rem] p-[1rem] overflow-y-auto">
          <div className="text-2xl border-[0.2rem] border-black p-[0.5rem] bg-white">
            <img
              className="w-[50vw] lg:w-full object-contain"
              src="center.png"
              alt="center for human rights in iran"
            />
          </div>
          <div className="bg-white lg:w-[60vw] border-[0.2rem] border-black p-[0.5rem]">
            The Center for Human Rights in Iran is an independent, nonprofit
            organization founded in 2008 to protect and promote human rights in
            Iran.
          </div>
          <div className="bg-white lg:w-[60vw] border-[0.2rem] border-black p-[0.5rem]">
            Comprised of leading Iranian human rights experts, lawyers, and
            journalists, the Center is a leading organization in research and
            documentation, outreach and awareness raising, and advocacy and
            policy recommendations on the international stage.
          </div>
          <a
            className="underline bg-white border-[0.2rem] border-black p-[0.5rem] hover:text-blue-800 text-sm w-[50vw] lg:w-[20vw]"
            href="https://iranhumanrights.org/"
          >
            Learn more.
          </a>
        </div>
      </div>
    </div>
  );
};

export default CenterPage;
