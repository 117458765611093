import {
  Environment,
  OrbitControls,
  PresentationControls,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import { Background } from "../comps/Background";
import { Hoodie } from "../comps/Hoodie";

const Env = () => {
  return (
    <div className="w-full h-[100vh] bg-pink-200">
      <Background mobile={false} />
      <Canvas>
        <pointLight position={[-5, 0, 5]} intensity={1.2} />
        <rectAreaLight
          position={[0, 0, 10]}
          width={100}
          height={100}
          intensity={1}
          color="white"
          rotation={[0, 0, 0]}
        />
        <Environment
          background={false} // can be true, false or "only" (which only sets the background) (default: false)
          blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
          files={["px.png", "nx.png", "py.png", "ny.png", "pz.png", "nz.png"]}
          path="/"
          preset={"forest"}
          scene={undefined} // adds the ability to pass a custom THREE.Scene, can also be a ref
          encoding={undefined} // adds the ability to pass a custom THREE.TextureEncoding (default: THREE.sRGBEncoding for an array of files and THREE.LinearEncoding for a single texture)
        />

        <PresentationControls>
          <Hoodie intro={false} position={[0, -3, 0]} />
        </PresentationControls>
      </Canvas>
    </div>
  );
};

export default Env;
