const TOC = ({ mobile }: { mobile: boolean }) => {
  const supplier = "supplier";
  return (
    <>
      <div className="flex flex-col gap-[1rem] ">
        <div>
          ALLMÄNNA KÖPVILLKOR // GENERAL TERMS AND CONDITIONS OF PURCHASE
        </div>
        <div>
          Följande allmänna köpevillkor (”Villkoren”) är gällande mellan dig som
          konsument (”du”, ”dig”, ”din”) och Davoodi by Friends AB, med
          organisationsnummer: 556773-3547 (”Davoodi”, ”oss”, ”vi”) och kommer
          gälla för alla beställningar av produkter (”Varor”) som du lägger på
          Davoodis webbplats herbodyherchoice.se (”Webbplatsen”). Vid
          försäljning till konsument tillämpar Davoodi de tvingande regler som
          följer av tillämplig lagstiftning.
        </div>
        <div>
          The following general purchase terms and conditions
          (&quot;Terms&quot;) apply between you as a consumer (&quot;you&quot;,
          &quot;your&quot;) and Davoodi by Friends AB, with corporate identity
          number: 556773-3547 (&quot;Davoodi&quot;, &quot;us&quot;,
          &quot;we&quot;) and will apply to all orders for products (“Products”)
          that you place on the Davoodi website herbodyherchoice.se (the
          “Site”). When selling to consumers, Davoodi always complies with the
          mandatory regulations that follow from the national legislation.
        </div>
        <div>
          Villkoren är tillgängliga på både svenska och engelska. I händelse av
          motstridigheter mellan de olika språkversionerna ska den svenska äga
          företräde.
        </div>
        <div>
          The Terms are available both in Swedish and in English. In case of any
          inconsistency between the two language versions, the Swedish version
          shall prevail.
        </div>
        <div>Nedladdning PDF. PDF Download.</div>
        <div> 1. Generellt / In general</div>
        <div>
          1.1 Observera att beställningar endast kan göras av privatpersoner
          över 18 år som är bosatta i Sverige vid beställningstillfället.
        </div>
        <div>
          1.1 Please note that orders can only be placed by individuals over 18
          years of age who are resident in Sweden at the time of ordering.
        </div>
        <div>
          1.2 Davoodi förbehåller sig rätten att när som helst revidera
          Villkoren. Datum för den senaste uppdateringen finns i det sista
          avsnittet av Villkoren. Observera att de Villkor som gäller för din
          beställning alltid är den version av Villkoren som du har accepterat
          inför du genomfört din beställning på Webbplatsen, d.v.s. inte någon
          senare version. Du kommer tillhandahållas Villkoren i varaktig form
          efter köpet har genomförts.
        </div>
        <div>
          1.2 Davoodi reserves the right to amend and/or update the Terms at any
          time. The date of the last update can be found in the last section of
          the Terms. Please note that the Terms that apply to your order are
          always the version of the Terms that you have accepted before you
          placed your order on the Website, i.e. not any later 2 (1) version.
          You will be provided with the Terms in permanent form after the
          purchase has been completed.
        </div>
        <div> 1.3 Villkoren på Webbplatsen finns på svenska och engelska.</div>
        <div>
          1.3 The Terms on the Website are available in Swedish and English.
        </div>
        <div>2. Bolagsinformation / Company information</div>
        <div>
          2.1 Davoodi by Friends AB, med organisationsnummer: 556773-3547.
        </div>
        <div>
          2.1 Davoodi by Friends AB, with corporate identity number:
          556773-3547.
        </div>
        <div>
          2.2 a@alidavoodi.com - Stuartsgatan 2 412 60, Göteborg, Sverige
        </div>
        <div>
          2.2 a@alidavoodi.com - Stuartsgatan 2 412 60, Gothenburg, Sweden
        </div>
        <div>3. Avtal och beställning / Agreement and order</div>
        <div>
          {" "}
          3.1 Beställning av Varor görs på vår Webbplats herbodyherchoice.se.
          Genom att göra en beställning på Webbplatsen bekräftar du att du har
          läst och accepterar Villkoren [samt har läst och förstått vår
          integritetspolicy som du även hittar här].
        </div>
        <div>
          {" "}
          Ordering of Products is done on our Website herbodyherchoice.se. By
          placing an order on the Website, you confirm that you have read and
          accept the Terms [and have read and understood our privacy policy,
          which you can also find here].
        </div>
        <div>
          {" "}
          3.2 Innan du lägger en beställning får du möjlighet att granska dina
          val, den information du har lämnat, det totala priset på din
          beställning och korrigera eventuella inmatningsfel. Observera att
          Varor i din varukorg inte är reserverade och kan köpas av andra kunder
          tills du har lagt beställningen i kassan och fått din Orderbekräftelse
          (definierat nedan).
        </div>
        <div>
          3.2 Before placing an order, you will be given the opportunity to
          review your choices, the information you have provided, the total
          price of your order and correct any input errors. Please note that
          Products in your shopping cart are not reserved and may be purchased
          by other customers until you have placed the order at checkout and
          received your Order Confirmation (defined below).
        </div>
        <div>
          3.3 Om långvarig inaktivitet gör att din anslutning till Webbplatsen
          misslyckas, kan ditt urval av Varor gå förlorat. I sådana fall kommer
          du att behöva ange ditt urval av Varor i varukorgen igen.
        </div>
        <div>
          3.3 If prolonged inactivity causes your connection to the Website to
          fail, your selection of Products may be lost. In such cases, you will
          need to enter your selection of Products in the shopping cart again.{" "}
        </div>
        <div>
          3.4 Ett bindande avtal med Davoodi ingås när Davoodi bekräftar ditt
          köp genom att skicka en orderbekräftelse till dig
          (”Orderbekräftelse”). Observera därmed att alla beställningar som görs
          av dig på Webbplatsen behöver accepteras av oss för att avtalet ska ha
          kommit till stånd. Vi kan helt självständigt välja att inte acceptera
          din beställning av någon anledning utan något ansvar gentemot dig. Det
          är alltså 3 (1) först vid ditt mottagande av Orderbekräftelsen som
          avtalet ska anses bindande mellan oss och dig.
        </div>
        <div>
          3.4 A binding agreement with Davoodi is entered into when Davoodi
          confirms your purchase by sending you an order confirmation
          (&quot;Order Confirmation&quot;). Please note that all orders placed
          by you on the Website need to be accepted by us in order for the
          agreement to be concluded. We may, in our sole discretion, choose not
          to accept your order for any reason without any liability to you. It
          is therefore only upon your receipt of the Order Confirmation that the
          agreement shall be considered binding.
        </div>
        <div>
          3.5 Det är viktigt att de kontaktuppgifter som du har angett vid
          beställning av Varor på Webbplatsen är korrekta och uppdaterade.
          Villkoren utgör tillsammans med din beställning, som bekräftats genom
          Orderbekräftelsen, avtalsdokumentationen för ditt köp. Villkoren
          kommer även att finnas tillgängliga i Orderbekräftelsen. Vi uppmanar
          dig att spara Orderbekräftelsen för eventuella kontakter med Davoodi:s
          kundtjänst.
        </div>
        <div>
          3.4 It is important that the contact details you have entered when
          ordering Products on the Website are correct and up to date. The
          Terms, together with your order, as confirmed by the Order
          Confirmation, constitute the contractual documentation for your
          purchase. The Terms will also be available in the Order Confirmation.
          We encourage you to save the Order Confirmation for any contact with
          Davoodi&#39;s customer service.
        </div>
        <div>4. Varors tillgänglighet / Availability of Products</div>
        <div>
          4.1 Observera att Varorna på Webbplatsen endast erbjuds i begränsad
          upplaga och för ett särskilt välgörande ändamål, vilket beskrivs
          närmare på Webbplatsen. Davoodi kan därmed aldrig garantera att alla
          Varor (eller storlekar/varianter) som visas på Webbplatsen finns i
          lager eller är tillgängliga för köp när du gör din beställning. Om
          Varan som du lagt i din beställt inte längre är tillgänglig kommer du
          att kontaktas via e-post kort efter att du lagt din beställning. Vi
          kommer då att återbetala dig köpbeloppet om vi redan har fått
          betalning från dig.
        </div>
        <div>
          4.1 Please note that the Products on the Website are only offered in
          limited edition and for a special charitable purpose, which is
          described in more detail on the Website. Davoodi can therefore never
          guarantee that all Products (or sizes/variants) shown on the Website
          are in stock or available for purchase when you place your order. If
          the Product that you placed in your order is no longer available, you
          will be contacted via email shortly after placing your order. We will
          then refund you the purchase amount if we have already received
          payment from you.{" "}
        </div>
        <div> 5. Färger och Produktbilder / Colors and Product images</div>
        <div>
          Varorna som visas på Webbplatsen ska återspegla Varornas verkliga
          färger och andra kvaliteter så långt som vi har möjlighet till.
          Observera dock att på grund av färginställningarna på dator och
          programvara kan färgerna på Varornas som 4 (1) visas på bilderna på
          Webbplatsen skilja sig något från deras faktiska färger. Davoodi kan
          inte hållas ansvarig för sådana avvikelser.
        </div>
        <div>
          The Products displayed on the Website shall reflect the true colors
          and other qualities of the Products as far as we are able. However,
          please note that due to computer and software color settings, the
          colors of the Products shown in the images on the Website may differ
          slightly from their actual colors. Davoodi cannot be held responsible
          for such deviations.
        </div>
        <div>6. Priser och avgifter / Prices and fees </div>
        <div>
          6.1 Vid beställning av Varor på Webbplatsen gäller de priser som anges
          i kassan. De priser som anges på Webbplatsen uppdateras i realtid och
          anges i svenska kronor, inklusive moms.
        </div>
        <div>
          6.1 When ordering Products on the Website, the prices specified in the
          checkout apply. The prices stated on the Website are updated in real
          time and stated in Swedish kronor, including VAT.
        </div>
        <div>
          {" "}
          6.2 Eventuella leveranskostnader är inte inkluderat i priset och kan
          tillkomma för din beställning av Varor. Leveranskostnader presenteras
          i sådant fall tydligt under beställningsprocessen. Eventuella avgifter
          för val av betalningsmetod kan också tillkomma, beroende på ditt val
          av betalsätt.
        </div>
        <div>
          6.2 Possible delivery costs are not included in the price and may be
          added to your order of Products. In such cases, delivery costs are
          clearly presented during the ordering process. Fees connected with
          selection of payment method may also apply, depending on your choice
          of payment method.
        </div>
        <div>7. Betalsätt / Payment method</div>
        <div>
          7.1 Följande betalningsmetoder accepteras på Webbplatsen: The
          following payment methods are or might in the future be accepted on
          the Website:
        </div>
        <div>
          7.2 Kortbetalning / Payment by card 7.3 Swish / Swish 7.4 PayPal /
          PayPal 7.5 Klarna Invoice / Klarna Invoice
        </div>
        <div>8. Leverans / Delivery</div>
        <div>
          8.1 Varor levereras till dig med det leveransalternativ som du väljer
          i kassan när du lägger din beställning på Webbplatsen. Leveranstiden
          baseras på artiklarna i varukorgen, ditt postnummer samt det fraktsätt
          som valts. I kassan finner du en uppskattad leveranstid för just dina
          Varor. Denna information finner du även i din Orderbekräftelse när
          köpet har genomförts.
        </div>
        <div>
          8.1 Products are delivered to you using the delivery option you select
          at checkout when you place your order on the Website. The delivery
          time is based on the 5 (1) items in the shopping cart, your postcode
          and the shipping method chosen. In the checkout you will find an
          estimated delivery time for your particular Products. You will also
          find this information in your Order Confirmation when the purchase has
          been completed.{" "}
        </div>
        <div>
          {" "}
          8.2 Leverans av beställda Varor sker genom {supplier}
          via de olika alternativen du ser nedan.
        </div>
        <div>
          8.2 Delivery of ordered Products takes place through {supplier} via
          the various options set out below below.
        </div>
        <div>8.3 {supplier}</div>
        <div>8.3 {supplier}</div>
        <div> 8.4 Tydliggör villkor i förhållande till leverantör.</div>
        <div>8.4 Clarify conditions in relation to supplier.</div>
        <div>
          8.5 När Varan har lämnat vårt lager övergår leveransansvaret till
          fraktbolagen som ska leverera din order inom utsatt tid. När Vi har
          skickat din order får du en leveransbekräftelse med ett kollinummer
          eller en spårningslänk till din e- postadress och/eller
          leveransinformation via e-post från det leveransföretag ovan som
          ansvarar för frakten för dina Varor.
        </div>
        <div>
          8.6 When the Product has left our warehouse, responsibility for
          delivery passes to the shipping companies who must deliver your order
          within the specified time. Once we have dispatched your order, you
          will receive a delivery confirmation with a parcel number or a
          tracking link to your email address and/or delivery information via
          email from the delivery company above responsible for shipping your
          Products.
        </div>
        <div>
          8.7 Även om Vi genom våra underleverantörer eftersträvar att leverera
          Varor inom angivna tidsramar, kan leveransen ta längre tid på grund av
          oväntade händelser. Leveranstiderna tar heller inte hänsyn till
          eventuella förseningar orsakade av betalningsproblem på din bank
          och/eller lagertillgänglighet av Varan. Vid en leveransförsening kan
          du kontakta oss genom våra kontaktuppgifter på Webbplatsen så hjälper
          Vi till att lokalisera din beställning. Om inte annat särskilt
          avtalats och en leverans tar mer än 30 arbetsdagar har du rätt att
          häva köpet, förutsatt att förseningen inte beror på dig som kund.
        </div>
        <div>
          8.7 Although we, through our subcontractors, endeavor to deliver
          Products within the specified time frames, delivery may take longer
          due to unexpected events. The delivery times also do not take into
          account any delays caused by payment problems at your bank and/or
          stock availability of the Product. In the event of a delivery delay,
          you can contact us through our contact details on the Website and 6
          (1) we will help locate your order. Unless otherwise specifically
          agreed and a delivery takes more than 30 working days, you have the
          right to cancel the purchase, provided that the delay is not due to
          you as a customer.{" "}
        </div>
        <div>
          8.8 Om ditt paket ska lösas ut av dig, måste du göra det inom den tid
          som anges i aviseringen. Paket ska normalt hämtas ut personligen med
          giltig legitimation och kolli-ID. Du får alltid en avisering som visar
          var och när Varan ska hämtas ut. Avisering kan ske via e-post, vanlig
          post samt, om du har lämnat ditt mobilnummer, även via telefonsamtal
          eller SMS.
        </div>
        <div>
          8.8 If your package is to be redeemed by you, you must do so within
          the time specified in the notification. Parcels must normally be
          picked up in person with valid identification and package ID. You
          always receive a notification that shows where and when the Product is
          to be picked up. Notification can take place via e- mail, regular mail
          and, if you have provided your mobile number, also via phone call or
          SMS.
        </div>
        <div>9. Ej uthämtade Varor / Unclaimed Products</div>
        <div>
          Om du inte hämtar upp, eller tar emot, Varor som du köpt så kommer
          ditt paket att skickas tillbaka till Davoodi. Vi förbehåller oss
          rätten att debitera dig för samtliga kostnader som uppstår i samband
          med sådan retur. Därför tar vi ut en avgift på [SEK X] om ett paket ej
          hämtas ut. Dessa avgifter debiteras separat via faktura, alternativt
          kvittas vid kort- och direktbetalning.
        </div>
        <div>
          If you do not pick up, or receive, Products that you purchased, your
          package will be sent back to Davoodi. We reserve the right to charge
          you for all costs incurred in connection with such return. Therefore,
          we charge a fee of [SEK X] if a package is not collected. These fees
          are charged separately via invoice, alternatively offset by card and
          direct payment.
        </div>
        <div>10. Ångerrätt och retur / Right of withdrawal and return</div>
        <div>
          {" "}
          Varorna och ditt köp av din vara helt avsett för att bidra till de
          välgörande ändamålen som framgår av Webbplatsen och endast finns
          tillgängliga i ett begränsat sortiment och under en begränsad tid.
          Detta innebär att ditt köp i enlighet med 2 kap. 11 § distans- och
          avtal utanför affärslokaler (SFS 2005:59) (”DAL”) är undantaget från
          ångerrätten och du kan således inte ångra ditt köp.
        </div>
        <div>
          {" "}
          The Products and your purchase of your products are entirely intended
          to contribute to the charitable purposes set out on the Website and
          are only available in a limited range and for a limited time. This
          means that your purchase in accordance with ch. 2 § 11 distance and
          off-premises contracts (SFS 2005:59) (&quot;DAL&quot;) is exempt from
          the right of withdrawal and you cannot therefore withdraw your
          purchase.
        </div>
        <div>11. Reklamation / Complaint </div>
        <div>
          11.1 Om det är något fel på din Vara har du rätt att reklamera den.
          Reklamationer som görs inom två månader från det att du upptäckt felet
          anses alltid ha lämnats i rätt 7 (1) tid. Du har tre års
          reklamationsrätt på Varor inköpta på Webbplatsen , eller annan tid som
          gäller enligt lag.
        </div>
        <div>
          11.1 If there is something wrong with your Product, you have the right
          to make a complaint to Davoodi. Complaints made within two months from
          the time you discovered the error are always considered to have been
          submitted in time. You have a three-year right to complain about
          Products purchased on the Website, or another period that applies
          according to law.
        </div>
        <div>
          11.2 Vill du göra gällande fel i en Vara ska du, så snart som möjligt
          efter att felet upptäckts, kontakta oss genom de kontaktuppgifter som
          anges längst upp i dessa Villkor. Du ska då meddela oss att du önskar
          att reklamera en Vara. Vi kommer att be dig att skicka en beskrivning
          av Varans fel och bildmaterial som tydligt bevisar felet, så att vi
          kan hjälpa dig vidare i processen. Vi tillser att retursedel
          tillhandahålls, eller att returfrakt på annat sätt arrangeras, samt
          att returfrakt ombesörjs vid reklamation. Vid reklamation av en eller
          flera felaktiga Varor ska du på begäran av Davoodi kunna styrka ditt
          köp på Webbplatsen.
        </div>
        <div>
          11.2 If you wish to claim a fault in a Product, you must, as soon as
          possible after the fault has been discovered, contact us using the
          contact details stated at the top of these Terms. You must then notify
          us that you wish to make a complain about a Product. We will ask you
          to send a description of the Product&#39;s fault and visuals that
          clearly prove the fault, so that we can help you further in the
          process. We will ensure that a return slip is provided, or that return
          shipping is arranged in another way, and that return shipping is taken
          care of in the event of a complaint. In the event of a complaint for
          one or more faulty Products, you must be able to prove your purchase
          on the Website at the request of Davoodi.
        </div>
        <div>
          11.3 När reklamerad Vara returnerats och reklamationen godkänts kommer
          Davoodi att kompensera dig i enlighet med gällande lag. Vi strävar
          efter att detta ska ske inom 30 dagar från det att Vi mottagit
          reklamationen, men det kan i vissa fall ta längre tid.
        </div>
        <div>
          11.3 Once the Product claimed to be faulty has been returned and the
          claim approved by Davoodi, Davoodi will compensate you in accordance
          with applicable law. We aim for this to take place within 30 days of
          receiving the complaint, but in some cases it may take longer time.
        </div>
        <div>
          11.4 Vi förbehåller oss rätten att neka en reklamation om det visar
          sig att Varan inte är felaktig enligt lag. Om Varan befinns vara
          felfri debiteras en avgift för felsökning och frakt för att täcka
          Davoodis kostnader för returen. Vid reklamationer strävar Vi efter att
          följa riktlinjer från Allmänna Reklamationsnämnden eller motsvarande
          nämnder i andra europeiska länder, se www.arn.se, mer information
          finns på www.konsumentverket.se.
        </div>
        <div>
          We reserve the right to deny a complaint if it turns out that the
          Product is not defective according to national law. If the Product is
          found to be free of defects, a fault-finding and shipping fee will be
          charged to cover Davoodi&#39;s costs for the return. In the event of
          complaints, we strive to follow guidelines from the National Board for
          Consumer Disputes (sw. Allmänna Reklamationsnämnden) or 8 (1)
          equivalent boards in other European countries, see www.arn.se, more
          information is available at www.konsumentverket.se.
        </div>
        <div>
          12. Force Majeure / Force Majeure Davoodi ansvarar inte för
          förseningar orsakade av omständigheter som Davoodi inte kunnat råda
          över, exempelvis allmän arbetskonflikt, krig, eldsvåda, blixtnedslag,
          terroristattack, ändrad myndighetsbestämmelse, tekniska problem, fel i
          el-/tele-/dataförbindelser eller annan kommunikation samt fel eller
          försening i tjänster från underleverantör på grund av omständighet som
          här angivits eller som denne annars inte har kunnat råda över. Dessa
          omständigheter ska utgöra befrielsegrund som medför befrielse från
          skadestånd och andra påföljder. Om någon sådan situation skulle uppstå
          informerar Davoodi dig dels i början, dels i slutet av tiden för den
          aktuella situationen. Har omständigheten varat längre än två månader
          har både du och Davoodi rätt att häva köpet med omedelbar verkan.
        </div>
        <div>
          12. Davoodi is not responsible for delays caused by circumstances
          beyond Davoodi&#39;s control, such as general labor dispute, war,
          fire, lightning, terrorist attack, change of government regulation,
          technical problems, failure in electrical/telecommunication/data
          connections or other communication and failure or delay in services
          from a subcontractor due to a circumstance stated here or which the
          latter could not otherwise control. These circumstances shall
          constitute a basis for exemption which entails exemption from damages
          and other penalties. If any such situation should arise, Davoodi
          informs you both at the beginning and at the end of the time for the
          current situation. If the circumstance has lasted longer than two
          months, both you and Davoodi have the right to cancel the purchase
          with immediate effect.
        </div>
        <div> 13. Immateriella rättigheter / Intellectual property rights</div>
        <div>
          {" "}
          Allt innehåll på Webbplatsen, såsom text, grafik, logotyper, bilder,
          ljudklipp, digitala nedladdningar och annan mjukvara, samt alla
          immateriella rättigheter relaterade därtill (såsom upphovsrätter,
          designrättigheter, mönsterrättigheter och varumärken), tillhör Davoodi
          eller Davoodi leverantörer och är skyddat av svensk och internationell
          lag. Du förvärvar ingen äganderätt till sådant innehåll eller sådana
          immateriella rättigheter genom din användning av Webbplatsen eller
          genom köp av Varor på Webbplatsen.
        </div>
        <div>
          All content on the Website, such as text, graphics, logos, images,
          audio clips, digital downloads and other software, as well as all
          intellectual property rights related thereto (such as copyrights,
          design rights, pattern rights and trademarks), belong to Davoodi or
          Davoodi’s suppliers and are protected by Swedish and international
          law. You do not acquire any ownership rights to such content or
          intellectual property rights through your use of the Website or
          through the purchase of Products on the Website.
        </div>
        <div>
          14. Tillämplig lag, tvist och alternativt tvistlösningsförfarande /
          Applicable law, dispute and alternative dispute resolution procedure
        </div>
        <div>
          14.1 Tvist rörande tolkningen eller tillämpningen av Villkoren ska
          tolkas i enlighet med svensk lag. Disputes concerning the
          interpretation or application of the Terms shall be interpreted in
          accordance with Swedish law. 14.2 Om en tvist inte kan lösas i
          samförstånd med Davoodis kundtjänst, kan du vända dig till Allmänna
          reklamationsnämnden (”ARN”) genom att kontakta ARN på deras Webbplats
          www.arn.se eller via deras postadress nedan.
        </div>
        <div>
          14.1 If a dispute cannot be resolved in agreement with Davoodi&#39;s
          customer service, you can contact the National Board for Consumer
          Disputes (sw. Allmänna Reklamationsnämnden) (&quot;ARN&quot;) by
          contacting ARN on their website www.arn.se or via their postal address
          below.
        </div>
        <div>
          Allmänna Reklamationsnämnden Box 174 101 23 Stockholm 14.3 Du kan även
          lämna klagomål direkt online via EU-kommissionens plattform för
          medling i tvister, som du hittar här. Lämnar du in ett klagomål via
          denna plattform, vidarebefordras ditt ärende automatiskt till rätt
          tvistlösningsorgan. Detta tvistlösningsorgan tar sedan kontakt med
          Davoodi och försöker lösa tvisten utan att blanda in domstol.
        </div>
        <div>
          {" "}
          You can also submit complaints directly online via the European
          Commission&#39;s platform for mediation in disputes, which you can
          find here. If you submit a complaint via this platform, your case is
          automatically forwarded to the appropriate dispute resolution body.
          This dispute resolution body then contacts Davoodi and tries to
          resolve the dispute without court involvement. 14.4 Tvist ska i sista
          hand avgöras av allmän domstol. As a last resort disputes can be
          settled by a general court.
        </div>
      </div>
    </>
  );
};

export default TOC;
