/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import gsap from "gsap";

type GLTFResult = GLTF & {
  nodes: {
    default002: THREE.Mesh;
  };
  materials: {
    ["Material _38.001"]: THREE.MeshStandardMaterial;
  };
};

const HoodieHigh = () => {
  const { nodes, materials } = useGLTF(
    "/new-hoodie.glb"
  ) as unknown as GLTFResult;
  return (
    <>
      {" "}
      <mesh
        geometry={nodes.default002.geometry}
        material={materials["Material _38.001"]}
      />{" "}
    </>
  );
};

const HoodieLow = () => {
  const { nodes, materials } = useGLTF(
    "/new-hoodie-transformed.glb"
  ) as unknown as GLTFResult;
  return (
    <>
      {" "}
      <mesh
        geometry={nodes.default002.geometry}
        material={materials["Material _38.001"]}
      />{" "}
    </>
  );
};

export const Hoodie = ({ ...props }: JSX.IntrinsicElements["group"] | any) => {
  const group = useRef<THREE.Group>(null);

  useEffect(() => {
    if (group.current && !props.mobile) {
      const tl = gsap.timeline({ paused: true, repeat: 0, repeatDelay: 0 });
      tl.to(group.current.scale, {
        duration: 3,
        x: 6,
        y: 6,
        z: 6,
      });

      tl.to(group.current.scale, {
        duration: 3,
        x: 4,
        y: 4,
        z: 4,
      });

      tl.to(
        group.current.rotation,
        {
          duration: 6,
          x: 0,
          y: 3 * 2,
          z: 0,
          onComplete: () => props.setIntro(false),
        },
        "-=6"
      );
      tl.play();
    }

    if (group.current && props.mobile) {
      const tl = gsap.timeline({ paused: true, repeat: 0, repeatDelay: 0 });
      tl.to(group.current.scale, {
        duration: 6,
        x: 3.5,
        y: 3.5,
        z: 3.5,
      });

      tl.to(
        group.current.rotation,
        {
          duration: 6,
          x: 0,
          y: 3 * 2,
          z: 0,
          onComplete: () => props.setIntro(false),
        },
        "<"
      );
      tl.play();
    }
  }, [group]);

  return (
    <group
      scale={props.intro ? (props.mobile ? 1 : 2) : props.mobile ? 3.5 : 4}
      ref={group}
      {...props}
      dispose={null}
    >
      <group position={[0, -1, 0]}>
        <group rotation={[-Math.PI / 2, 0, 0]} scale={0.03}>
          {props.mobile ? <HoodieLow /> : <HoodieHigh />}
        </group>
      </group>
    </group>
  );
};
