import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import {
  functions,
  hoodiesCol,
  IHoodies,
  mailCol,
  ordersCol,
} from "../firebase-config";
import { v4 as uuid } from "uuid";

import {
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51MATPSDmRTNfufbzd3Ihc1SsOT6fjt862vQP4D01D7BlL5oiKdxFlmIN0jx5mlVM3CUKym9vAFyM5RW0CWMYalxU00WKE1z9J9"
);

// const stripePromise = loadStripe(
//   "pk_test_51MATPSDmRTNfufbzv6FI0nCmcUqhyQ0reIdNN0zCxxLPM3VptSVN2GzHqvaIwl6DLU8b6yeUQBORoKD2aAM2k7N300QUgRHadF"
// );

export const useCart = () => {
  const [hoodies, setHoodies] = useState([
    { size: "M", count: 0 },
    { size: "L", count: 0 },
  ]);

  const addOneToCart = ({ size }: { size: string }) => {
    setHoodies((hoodies) => {
      return hoodies.map((hoodie) => {
        if (hoodie.size === size) {
          return {
            ...hoodie,
            count: hoodie.count + 1,
          };
        }
        return hoodie;
      });
    });
    return true;
  };

  const removeOneFromCart = ({ size }: { size: string }) => {
    setHoodies((hoodies) => {
      return hoodies.map((hoodie) => {
        if (hoodie.size === size) {
          return { ...hoodie, count: hoodie.count - 1 };
        }
        return hoodie;
      });
    });
    return true;
  };

  const clearCart = () => {
    setHoodies([
      { size: "M", count: 0 },
      { size: "L", count: 0 },
    ]);
  };

  const getCartItems = () => {
    return hoodies;
  };

  const addMessage = httpsCallable(functions, "addMessage");

  const callIt = async ({ donation }: { donation: number }) => {
    // Set a Cookie
    // removed, this avoides multiple submits

    function setCookie(cookieName: string, cookieValue: string): void {
      let date = new Date();
      date.setTime(date.getTime() + 400 * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie =
        cookieName + "=" + cookieValue + "; " + expires + "; path=/";
    }

    const id = uuid();
    setCookie("sessionId", id);
    const order = {
      cart: hoodies,
      id: id,
    };
    await createOrder(order);

    // styore customer in firebase
    await addMessage({
      text: "messageText",
      hoodies: hoodies,
      donation: donation,
    }).then(async (result: any) => {
      console.log("result", result);
      const id = result.data.sessionId;
      console.log("id", id);

      // const hoodiesInDB = await checkHoodieStock();
      // const mHoodiesInCart = hoodies.find((hoodie) => hoodie.size === "M");
      // const lHoodiesInCart = hoodies.find((hoodie) => hoodie.size === "L");
      // console.log("hoodiesInDB", hoodiesInDB);

      // if (mHoodiesInCart) {
      //   const mStockInDB = hoodiesInDB[0].stock;
      //   const newStock = mStockInDB - mHoodiesInCart.count;
      //   await updateHoodieStock({ size: "medium", newStock: newStock });
      // }

      // if (lHoodiesInCart) {
      //   const lStockInDB = hoodiesInDB[1].stock;
      //   const newStock = lStockInDB - lHoodiesInCart.count;
      //   await updateHoodieStock({ size: "large", newStock: newStock });
      // }

      stripePromise.then((stripe: any) => {
        stripe.redirectToCheckout({ sessionId: id });
      });
    });
  };

  const checkHoodieStock = async () => {
    const itemDocs = await getDocs(query(hoodiesCol, orderBy("size", "desc")));
    const items: IHoodies[] = itemDocs.docs.map((doc) => ({
      ...doc.data(),
    }));
    console.log("items", items);
    return items;
  };

  const updateHoodieStock = async ({
    size,
    newStock,
  }: {
    size: string;
    newStock: number;
  }) => {
    const docRef = doc(hoodiesCol, size);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      await updateDoc(docRef, {
        stock: newStock,
      });
    } else {
      console.log("No such document!");
    }
  };

  const createOrder = async ({
    cart,
    id,
  }: {
    cart: { size: string; count: number }[];
    id: string;
  }) => {
    const orderRef = doc(ordersCol, id);

    await setDoc(orderRef, {
      cart: cart,
      id: id,
      date: serverTimestamp(),
      confirmed: false,
    });
    return orderRef;
  };

  const getOrderById = async ({ id }: { id: string }) => {
    const orderRef = doc(ordersCol, id);
    const orderSnap = await getDoc(orderRef);
    if (orderSnap.exists()) {
      return orderSnap.data();
    } else {
      console.log("No such document!");
    }
  };

  return {
    addOneToCart,
    removeOneFromCart,
    clearCart,
    getCartItems,
    callIt,
    checkHoodieStock,
    updateHoodieStock,
    createOrder,
    getOrderById,
  };
};
