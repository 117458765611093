import { Suspense } from "react";

export const Background = ({ mobile }: { mobile: boolean }) => {
  return (
    <>
      <Suspense
        fallback={<div className="h-[100vh] w-[100vw] bg-[#dcb3dd]"></div>}
      >
        <div
          key="gifDiv"
          className="fixed translate-x-[-10%] w-[120vw] h-[120vh] select-none crt bg-[#dcb3dd]  "
        >
          {mobile ? (
            <img
              className="w-full h-full object-cover"
              src="bg-mob.gif"
              alt="wlf"
              loading="lazy"
            />
          ) : (
            <img
              className="w-full h-full object-cover"
              src="bg.gif"
              alt="wlf"
              loading="lazy"
            />
          )}
        </div>
      </Suspense>
    </>
  );
};
