import {
  Loader,
  OrbitControls,
  OrbitControlsProps,
  PresentationControls,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import gsap from "gsap";

import { getAnalytics } from "firebase/analytics";

import CenterPage from "./pages/CenterPage";
import ConfirmationPage from "./pages/ConfirmationPage";

import { Hoodie } from "./comps/Hoodie";
import { Background } from "./comps/Background";

import { Route, Routes } from "react-router-dom";

import { MobileMarquee } from "./comps/MobileMarquee";
import { SVGMobile } from "./comps/SVGMobile";

import { useCart } from "./hooks/useCart";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TOC from "./pages/TOC";
import EmailList from "./pages/EmailList";
import { app, IHoodies, mailCol } from "./firebase-config";
import { v4 as uuid } from "uuid";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import Env from "./pages/Env";

const Scene = ({
  mobile,
  setIntro,
  intro,
  hotSpotOne,
}: {
  mobile: boolean;
  setIntro: any;
  intro: boolean;
  hotSpotOne: boolean;
}) => {
  const [orbit, setOrbit] = useState(false);

  const orbitRef = useRef<OrbitControlsProps | any>();

  useEffect(() => {
    if (hotSpotOne) {
      setOrbit(true);
    }
    const tl = gsap.timeline();

    if (!hotSpotOne && orbit) {
      tl.to(orbitRef.current.object.position, {
        duration: 1,
        x: 0,
        y: 0,
        z: 5,
      });
      tl.to(
        orbitRef.current.target,
        {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
          onComplete: () => {
            orbitRef.current.reset();
            setOrbit(false);
          },
        },
        "<"
      );
    }

    if (hotSpotOne) {
      tl.to(orbitRef.current.object.position, {
        duration: 1,
        x: 0,
        y: 0,
        z: 2,
      });
      tl.to(
        orbitRef.current.target,
        {
          duration: 1,
          x: 0,
          y: 0,
          z: 0,
        },
        "<"
      );
    }
  }, [hotSpotOne, orbit]);
  return (
    <>
      <OrbitControls
        enablePan={false}
        enableRotate={false}
        enableZoom={false}
        ref={orbitRef}
        enabled={orbit}
      />
      <PresentationControls
        enabled={true}
        zoom={1.3}
        polar={[-Math.PI / 4.5, Math.PI / 4.5]}
        speed={4}
      >
        <Hoodie
          setIntro={setIntro}
          intro={intro}
          mobile={mobile}
          position={[0, -2.5, 0]}
        />
      </PresentationControls>
    </>
  );
};

const CheckoutDiv = ({
  cartOpen,
  setCartOpen,
  hoodies,
  stockM,
  stockL,
  soldOut,
  mobile,
}: {
  cartOpen: boolean;
  setCartOpen: any;
  hoodies: IHoodies[];
  stockM: number;
  stockL: number;
  soldOut: boolean;
  mobile: boolean;
}) => {
  const [disableCheckout, setDisableCheckout] = useState(true);
  const [buttonMessage, setButtonMessage] = useState("CHECKOUT");
  const [showInfo, setShowInfo] = useState(false);
  const [showSoldOutDiv, setShowSoldOutDiv] = useState(true);
  const { addOneToCart, getCartItems, clearCart, callIt } = useCart();

  const [waitListInput, showWaitListInput] = useState(false);
  const [emailButtonMessage, setEmailButtonMessage] = useState("confirm");
  const [disableEmail, setDisableEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = uuid();
    setEmail("");
    setEmailButtonMessage("confirming...");
    console.log(email);

    const createMail = async () => {
      const mailRef = doc(mailCol, id);
      await setDoc(mailRef, {
        email: email,
        id: id,
        date: serverTimestamp(),
      });
      return mailRef;
    };
    const response = await createMail();
    setEmailButtonMessage("WE'LL BE IN TOUCH!");
    setDisableEmail(true);
    console.log("response", response);
    setTimeout(() => {
      showWaitListInput(false);
    }, 2000);
  };

  return (
    <>
      <AnimatePresence mode="wait" initial>
        {cartOpen && (
          <motion.div
            key="checkout-div"
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            exit={{ x: "100%" }}
            transition={{ duration: 1 }}
            className="fixed top-0 right-0 w-[100vw] h-full bg-white z-20 text-xl pb-[1vh] grid grid-rows-5 overflow-hidden"
          >
            {/* // checkout header */}
            <div className="flex flex-col w-full items-center gap-[1rem] ">
              <div className="w-full grid grid-cols-8 ">
                <button
                  onClick={() => {
                    if (showInfo) {
                      setShowInfo(false);
                    } else {
                      setCartOpen(!cartOpen);
                    }
                  }}
                  className="fixed h-[5vh] top-0 left-0 w-[10%] bg-white col-span-1 border-r-[0.2rem] border-b-[0.2rem] border-black flex justify-center items-center font-bold"
                >
                  X
                </button>
                <button
                  onClick={() => setShowInfo(!showInfo)}
                  className="w-full h-[5vh] bg-[#dcb3dd] col-span-8 flex justify-center items-center border-b-[0.2rem] border-black font-bold lg:p-0 pl-[5vw] text-sm "
                >
                  INFO//SHIPPING//POLICY
                </button>
              </div>
              <div className="flex flex-col gap-[0.5rem] items-center text-center text-sm">
                <div className="font-bold ">
                  ALL PROFIT GOES TO <br />
                  <a
                    href="/center-for-human-rights"
                    target={"_blank"}
                    className="underline hover:bg-[#dcb3dd]"
                    style={{ textDecorationColor: "#dcb3dd" }}
                  >
                    THE CENTER FOR HUMAN RIGHTS IN IRAN
                  </a>
                </div>
              </div>
            </div>

            {/* //images */}
            <div className="row-span-2 overflow-x-auto row-start-2 lg:row-span-3 w-full flex justify-center ">
              <Suspense fallback={null}>
                <div className="flex w-full h-full items-center justify-start">
                  <img
                    className="h-full w-full object-contain"
                    src="images/hoodie-front-1.webp"
                    alt="hoodie front"
                  />

                  <img
                    className="h-full w-full object-contain"
                    src="images/hoodie-back-1.webp"
                    alt="hoodie back"
                  />
                  {mobile && (
                    <img
                      className="h-[80%] w-[80%] object-contain px-[2rem]"
                      src="images/hbhc.webp"
                      alt="hoodie back"
                    />
                  )}
                </div>
              </Suspense>
            </div>

            {/* //buttons & input body */}
            <div className="lg:row-span-1 lg:row-start-5 row-span-2 flex flex-col lg:justify-center justify-start items-center lg:gap-[0.25rem] gap-[1rem] font-bold px-[0.5rem]">
              {/* // hoodie price */}
              <div className="w-full flex justify-center text-2xl">
                1600 SEK
              </div>

              {/* //ADD buttons */}
              <div className="grid grid-cols-6 lg:w-[60vw] w-full h-[6vh] ">
                {/* //add M to cart */}
                <button
                  disabled={soldOut || stockM === 0}
                  onClick={() => {
                    setDisableCheckout(false);
                    if (
                      stockM >
                      getCartItems().filter((item) => item.size === "M")[0]
                        .count
                    ) {
                      addOneToCart({ size: "M" });
                    }
                  }}
                  className="col-span-2 flex justify-center items-center bg-gray-100 border-l-[0.2rem] border-y-[0.2rem] border-black"
                >
                  {stockM === 0 || soldOut ? "SOLD OUT: Medium" : "ADD M"}
                </button>
                {/* //M's in cart */}
                <div className="flex justify-center items-center  border-y-[0.2rem] border-black ">
                  {getCartItems().filter((item) => item.size === "M")[0].count}
                </div>

                {/* //add L to cart */}
                <button
                  disabled={soldOut || stockL === 0}
                  onClick={() => {
                    setDisableCheckout(false);
                    if (
                      stockL >
                      getCartItems().filter((item) => item.size === "L")[0]
                        .count
                    ) {
                      addOneToCart({ size: "L" });
                    }
                  }}
                  className="col-span-2 flex justify-center items-center bg-gray-100 border-l-[0.2rem] border-y-[0.2rem] border-black "
                >
                  {stockL === 0 || soldOut ? "SOLD OUT: Large" : "ADD L"}
                </button>
                {/* //L's in cart */}
                <div className="flex justify-center items-center border-r-[0.2rem] border-y-[0.2rem] border-black ">
                  {getCartItems().filter((item) => item.size === "L")[0].count}
                </div>
              </div>

              {/* //checkout button */}
              <div
                className={`flex flex-col justify-start items-center pt-[1rem] lg:pt-0  `}
              >
                <motion.button
                  className={`border-[0.2rem] border-black w-[45vw] lg:w-[12.5vw] h-[7.5vh] rounded-lg ${
                    disableCheckout && buttonMessage === "CHECKOUT"
                      ? "bg-red-300"
                      : "bg-green-300"
                  }`}
                  whileHover={{ scale: disableCheckout ? 1 : 1.1 }}
                  whileTap={{ scale: 1 }}
                  disabled={disableCheckout}
                  onClick={() => {
                    setDisableCheckout(true);
                    setButtonMessage("CHECKING OUT...");
                    callIt({ donation: 0 });
                  }}
                >
                  {buttonMessage}
                </motion.button>
                {/* //clear button */}
                <button
                  onClick={() => {
                    setShowInfo(false);
                    setDisableCheckout(true);
                    clearCart();
                  }}
                  className="underline underline-offset-4 flex justify-center items-center"
                >
                  clear
                </button>
              </div>
            </div>

            {/* info page// */}
            <AnimatePresence mode="wait" initial>
              {showInfo && (
                <motion.div
                  key="info-div"
                  initial={{ y: "-100%" }}
                  animate={{ y: "0" }}
                  exit={{ x: "100%" }}
                  transition={{ duration: 0.5 }}
                  className="fixed top-[5vh] left-0 w-[100vw] min-h-[100vh] bg-white text-center text-sm z-[30]"
                >
                  <div className="h-[90vh] overflow-auto flex flex-col gap-[1rem] p-[1rem]">
                    <div className="font-black">SHIPPING & RETURNS</div>
                    <div>
                      75 SEK shipping in Sweden. <br /> 150 SEK/€15 shipping to
                      Europe <br />
                      400 SEK/$40 shipping Internationally <br />
                      <span className="font-italic underline">
                        NO RETURNS ACCEPTED SINCE IT IS FOR CHARITY.
                      </span>
                    </div>

                    <div className="font-black">HOW WE WILL USE THE PROFIT</div>
                    <div>
                      We will deduct the cost of production and logistics,{" "}
                      <br />
                      then all profit will be transferred to{" "}
                      <a
                        href="/center-for-human-rights"
                        target="_blank"
                        className="underline hover:bg-[#dcb3dd]"
                        style={{ textDecorationColor: "#dcb3dd" }}
                      >
                        The Center for Human Rights in Iran.
                      </a>
                    </div>
                    <div className="font-black">MEASUREMENTS</div>
                    <div>
                      <div>A: Size M = 61 CM // Size L = 65 CM</div>
                      <div>B. Size M = 71,5 CM // Size L = 73,5 CM </div>
                      <div>C. Size M = 64 CM // Size L = 64 CM</div>
                      <div>D. Size M = 28 CM // Size L = 30 CM </div>
                      <div>E. Size M = 58 CM // Size L = 62 CM</div>
                    </div>
                    <div className="w-full p-[1rem] flex justify-center">
                      <img alt="sizes" src="size.png" />
                    </div>
                    <div className="flex justify-center gap-[1rem] p-[1rem]">
                      <a
                        target="_blank"
                        href="/privacy-policy"
                        className="hover:underline"
                        rel="noreferrer"
                      >
                        privacy policy // integritespolicy
                      </a>
                      <a
                        target="_blank"
                        href="/privacy-policy"
                        className="hover:underline"
                      >
                        terms & conditions // köpvillkor
                      </a>
                    </div>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* //sold out div */}
            <AnimatePresence mode="wait" initial>
              {/* //sold out div */}
              {soldOut && showSoldOutDiv && (
                <motion.div
                  key="sold-out-div"
                  initial={{ x: "100vw" }}
                  animate={{
                    x: showSoldOutDiv ? "0" : "100vw",
                    transition: { delay: 2 },
                  }}
                  exit={{ x: "100vw" }}
                  transition={{ duration: 0.5 }}
                  className="fixed bottom-[10vh] left-[10vw] h-[50vh] w-[80vw] bg-black border-2 text-white bg-opacity-80"
                >
                  <form
                    onSubmit={handleSubmit}
                    className="w-full h-full flex flex-col justify-center gap-[2rem] items-center text-center p-[2rem]"
                  >
                    <div>
                      it looks like we're sold out for now! <br />{" "}
                      {mobile && <br />}
                      join our wait list to be notified when we restock, or in
                      time for our next drop!
                    </div>
                    {/* IT GOES HERE! */}
                    <input
                      className="text-black p-2"
                      required
                      type="email"
                      placeholder="email goes here"
                      minLength={5}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      className="border px-[0.25rem]"
                      disabled={disableEmail}
                      type="submit"
                    >
                      {emailButtonMessage}
                    </button>
                  </form>
                  <button
                    onClick={() => setShowSoldOutDiv(false)}
                    className="absolute top-[1vh] right-[1vw] underline underline-offset-2 px-[0.25rem]"
                  >
                    close
                  </button>
                </motion.div>
              )}
            </AnimatePresence>

            {/* checkout footer */}
            <div>
              <div className="fixed bottom-[1vh] left-[1vw] text-sm">
                Her Body Her Choice
              </div>

              <div className="fixed bottom-[1vh] right-[1vw] text-sm">
                2022 by Arcadian AB
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Home = ({ mobile, launch }: { mobile: boolean; launch: boolean }) => {
  const [hoodies, setHoodies] = useState([{ size: "", stock: 0 }]);
  const [stockM, setStockM] = useState(0);
  const [stockL, setstockL] = useState(0);
  const [soldOut, setSoldOut] = useState(false);

  const [cartOpen, setCartOpen] = useState(false);
  const [intro, setIntro] = useState(true);

  const { checkHoodieStock } = useCart();

  useLayoutEffect(() => {
    const handleCheck = async () => {
      const res = await checkHoodieStock();
      setHoodies(res);
      setStockM(res[0].stock);
      setstockL(res[1].stock);
      if (res[0].stock === 0 && res[1].stock === 0) {
        setSoldOut(true);
      }
    };
    handleCheck();
  }, []);

  // hotspot stuff
  const [hotspotOne, setHotspotOne] = useState(false);

  //

  //pre launch stuff
  const [waitListInput, showWaitListInput] = useState(false);
  const [emailButtonMessage, setEmailButtonMessage] = useState("confirm");
  const [disableEmail, setDisableEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const id = uuid();
    setEmail("");
    setEmailButtonMessage("confirming...");
    console.log(email);

    const createMail = async () => {
      const mailRef = doc(mailCol, id);
      await setDoc(mailRef, {
        email: email,
        id: id,
        date: serverTimestamp(),
      });
      return mailRef;
    };
    const response = await createMail();
    setEmailButtonMessage("WE'LL BE IN TOUCH!");
    setDisableEmail(true);
    console.log("response", response);
    setTimeout(() => {
      showWaitListInput(false);
    }, 2000);
  };

  //to here

  // limited drop stuff
  const [showLimitedDiv, setShowLimitedDiv] = useState(false);

  //

  return (
    <>
      <AnimatePresence>
        <Suspense fallback={null}>
          <motion.div
            key="bigDiv"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            className="relative h-[100vh] w-[100vw] overflow-hidden bg-[#dcb3dd]"
          >
            <Background mobile={mobile} />

            {/* checkout div */}
            <CheckoutDiv
              stockM={stockM}
              stockL={stockL}
              soldOut={soldOut}
              hoodies={hoodies}
              cartOpen={cartOpen}
              setCartOpen={setCartOpen}
              mobile={mobile}
            />

            {/* //SVG */}
            <Suspense fallback={null}>
              <motion.div
                key="svgDiv"
                className="fixed lg:top-[5%] top-[7.5%] left-[50%] translate-x-[-50%] lg:w-[70%] w-[95vw] "
              >
                <SVGMobile />
              </motion.div>
            </Suspense>
            {/* //Canvas */}
            <motion.div
              key="canvasDiv"
              className="h-full w-full z-20 touch-none bg-gradient-to-r"
            >
              <Suspense fallback={null}>
                <Canvas>
                  {/* <pointLight position={[-10, 5, 10]} intensity={0.5} /> */}
                  <pointLight position={[-5, 0, 5]} intensity={1} />
                  <rectAreaLight
                    position={[0, 0, 10]}
                    width={100}
                    height={100}
                    intensity={1}
                    color="white"
                    rotation={[0, 0, 0]}
                  />
                  <Suspense fallback={null}>
                    <group position={[0, mobile ? 0 : -0.5, 0]}>
                      <Scene
                        hotSpotOne={hotspotOne}
                        setIntro={setIntro}
                        intro={intro}
                        mobile={mobile}
                      />
                    </group>
                  </Suspense>
                </Canvas>
                <Loader />
              </Suspense>
            </motion.div>
            {/* //CONTRIBUTE button */}
            {launch && (
              <motion.button
                key="CONTRIBUTE-button"
                initial={{ x: "100vw" }}
                animate={{
                  x: intro ? "100vw" : "50%",
                  transition: { delay: 0.5 },
                }}
                whileHover={{
                  scale: 1.1,
                }}
                disabled={false}
                whileTap={{ scale: 0.9 }}
                onClick={() => setCartOpen(true)}
                //replace hidden with flex to show button
                className=" flex fixed bottom-[10%] right-[50vw] w-[45%] lg:w-[12.5vw] h-[7vh] bg-white text-xl px-[1.5rem] py-[0.5rem] font-bold rounded-md border-[0.2rem] border-black shadow-black shadow justify-center items-center text-center "
              >
                CONTRIBUTE
              </motion.button>
            )}

            <motion.div
              initial={{ x: "100vw" }}
              animate={{
                x: intro ? "100vw" : "0",
                transition: { delay: 1 },
              }}
              className="fixed top-[1vh] right-[1vw] flex gap-[1vh]"
            >
              {!mobile && (
                <button
                  className="bg-white bg-opacity-50 py-[0.25rem] px-[0.75rem] border-[0.1rem] border-black rounded-md gap-[1vh] font-bold lg:hover:invert text-center"
                  onClick={() => {
                    setHotspotOne(!hotspotOne);
                  }}
                >
                  {hotspotOne ? "zoom out" : "zoom in"}
                </button>
              )}
              <a
                href="images/hbhc.png"
                download={true}
                className="bg-white bg-opacity-50 py-[0.25rem] px-[0.75rem] border-[0.1rem] border-black rounded-md gap-[1vh] font-bold lg:hover:invert text-center "
              >
                download
              </a>
              <a
                key="insta-link"
                href="https://www.instagram.com/herbodyherchoice.se/?igshid=YmMyMTA2M2Y%3D"
                target="_blank"
                rel="noreferrer"
                className="bg-white bg-opacity-50 py-[0.25rem] px-[0.75rem] border-[0.1rem] border-black rounded-md font-bold lg:hover:invert text-center"
              >
                insta
              </a>
            </motion.div>

            <>
              <motion.div
                initial={{ x: "-50vw" }}
                animate={{
                  x: intro ? "-50vw" : "0",
                  transition: { delay: 1 },
                }}
                className="fixed top-[1vh] left-[1vw] font-bold flex flex-col rounded-m gap-[1vh]"
              >
                <button
                  onClick={() => setShowLimitedDiv(!showLimitedDiv)}
                  className="bg-white border-[0.1rem] rounded-md border-black lg:hover:invert px-[0.5rem] py-[0.25rem] bg-opacity-50 w-[35vw] lg:w-[10vw]"
                >
                  {soldOut ? "sold out?" : "limited item"}
                </button>
                {showLimitedDiv && (
                  <form
                    onSubmit={handleSubmit}
                    className="bg-white border-[0.1rem] border-black rounded-md px-[0.5rem] py-[0.25rem] bg-opacity-50 flex flex-col"
                  >
                    <p>join our wait list</p>
                    <p>for the next drop</p>
                    <input
                      required
                      type="email"
                      minLength={5}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      disabled={disableEmail}
                      type="submit"
                      className="h-full"
                    >
                      {emailButtonMessage}
                    </button>
                  </form>
                )}
              </motion.div>
            </>

            <MobileMarquee launch={launch} intro={intro} />
          </motion.div>
        </Suspense>
      </AnimatePresence>
    </>
  );
};

function App() {
  const [mobile, setMobile] = useState(window.innerWidth < 1024);
  const [launch, setLaunch] = useState(true);
  const [showCookies, setShowCookies] = useState(true);

  const handleConfirmCookies = async (accept: boolean) => {
    setShowCookies(false);
    if (accept) {
      document.cookie = "acceptCookies=true;max-age=31536000";
      const analytics = await getAnalytics(app);
    } else {
      document.cookie = "acceptCookies=false;max-age=31536000";
    }
  };

  useLayoutEffect(() => {
    const cookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("acceptCookies"))
      ?.split("=")[1];
    if (cookie === "true") {
      setShowCookies(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        {/* //actual pages */}
        <Route path="/" element={<Home launch={launch} mobile={mobile} />} />
        <Route path="/env" element={<Env />} />

        <Route
          path="/confirmation"
          element={<ConfirmationPage mobile={mobile} />}
        />
        <Route path="/admin" element={<EmailList mobile={mobile} />} />
        <Route
          path="/center-for-human-rights"
          element={<CenterPage mobile={mobile} />}
        />

        {/* //legal pages */}
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy mobile={mobile} />}
        />
        <Route path="/toc" element={<TOC mobile={mobile} />} />
      </Routes>
      {showCookies && (
        <div className="fixed bottom-0 h-[25vh] bg-black border-2 border-white text-white w-full flex flex-col justify-around text-sm ">
          <div className="text-center pt-[0.5rem] px-[1rem]">
            herbodyherchoice.se make use of functional and analytical cookies
          </div>
          <div className="flex justify-center gap-[2rem]">
            <button
              onClick={() => handleConfirmCookies(false)}
              className="border-2 border-white px-[2rem]"
            >
              reject
            </button>
            <button
              onClick={() => handleConfirmCookies(true)}
              className="border-2 border-white px-[2rem]"
            >
              accept
            </button>
          </div>
          <button
            onClick={() => handleConfirmCookies(true)}
            className="underline underline-offset-2 w-full border-b pb-2"
          >
            close
          </button>
          <a
            href="/privacy-policy"
            target={"_blank"}
            className="underline underline-offset-2 w-full text-center"
          >
            learn more
          </a>
        </div>
      )}
    </Suspense>
  );
}

export default App;
