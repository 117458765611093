// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  DocumentData,
  CollectionReference,
} from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAs-RNok6kUC_Pf2eqTb3oyoLsRJa-WXlc",
  authDomain: "her-body-her-choice.firebaseapp.com",
  projectId: "her-body-her-choice",
  storageBucket: "her-body-her-choice.appspot.com",
  messagingSenderId: "1058115312655",
  appId: "1:1058115312655:web:04c2461f04eb59ad70d77a",
  measurementId: "G-JH76GL92FT",
  // apiKey: "AIzaSyAO_lbEyHaEkKSPH8VBvL5if4Yk_ThJj3s",
  // authDomain: "hbhc-second-coming.firebaseapp.com",
  // projectId: "hbhc-second-coming",
  // storageBucket: "hbhc-second-coming.appspot.com",
  // messagingSenderId: "390259243626",
  // appId: "1:390259243626:web:392882e357b5f162b46813",
  // measurementId: "G-S94TF5FB50",
};

// Initialize Firebase
// export const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
// export const db = getFirestore(app);
export const functions = getFunctions(app);
const analytics = getAnalytics(app);

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

export interface IMail {
  email: string;
  date: any;
  id: string;
}

export interface IHoodies {
  size: string;
  stock: number;
}

export interface IOrder {
  cart: { size: string; count: number }[];
  id: string;
  confirmed: boolean;
  date: any;
}

export const mailCol = createCollection<IMail>("mail");
export const hoodiesCol = createCollection<IHoodies>("hoodies");
export const ordersCol = createCollection<IOrder>("orders");
